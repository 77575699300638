var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"profile_form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[(!_vm.invoice)?_c('base-input',{attrs:{"label":((_vm.$t('COMMON.INVOICE')) + " (*)"),"placeholder":_vm.$t('COMMON.INVOICE')}},[_c('reseller-invoice-selector',{attrs:{"resellerInvoice":_vm.resellerPayment.invoice.id,"filterStatus":_vm.INVOICE_STATUS_VALIDATED,"filterable":true,"showAll":false},on:{"resellerInvoiceChanged":function (resellerInvoiceId, resellerInvoice) {
          _vm.resellerPayment.invoice.id = resellerInvoiceId;
          _vm.resellerPayment.amount = resellerInvoice.total_remaining_payment;
          _vm.onFormChanged();
        }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.invoice}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.SOURCE')) + " (*)"),"placeholder":_vm.$t('COMMON.SOURCE')}},[_c('el-select',{attrs:{"name":"Source","placeholder":"Source"},on:{"change":function () {
          _vm.onFormChanged();
        }},model:{value:(_vm.resellerPayment.source),callback:function ($$v) {_vm.$set(_vm.resellerPayment, "source", $$v)},expression:"resellerPayment.source"}},_vm._l((_vm.sourcesOption),function(label,val){return _c('el-option',{key:val,attrs:{"value":val,"label":label}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.source}}),_c('base-input',{staticClass:"d-none",attrs:{"label":_vm.$t('COMMON.STATUS')}},[_c('el-select',{attrs:{"name":"status"},model:{value:(_vm.resellerPayment.status),callback:function ($$v) {_vm.$set(_vm.resellerPayment, "status", $$v)},expression:"resellerPayment.status"}},_vm._l((_vm.statusesOption),function(label,val){return _c('el-option',{key:val,attrs:{"value":val,"label":label},on:{"change":function () {
            _vm.onFormChanged();
          }}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.status}}),_c('base-input',{attrs:{"label":((_vm.$t('COMMON.AMOUNT')) + " (*)"),"placeholder":"amount","type":"number","step":"0.0001","input-classes":"form-control-alternative"},on:{"change":function () {
        _vm.onFormChanged();
      }},model:{value:(_vm.resellerPayment.amount),callback:function ($$v) {_vm.$set(_vm.resellerPayment, "amount", $$v)},expression:"resellerPayment.amount"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.amount}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.resellerPayment.excerpt),callback:function ($$v) {_vm.$set(_vm.resellerPayment, "excerpt", $$v)},expression:"resellerPayment.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}}),(_vm.resellerPayment.source != _vm.PAYMENT_SOURCE_MANUAL)?_c('div',[_c('h2',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("RESELLER_PAYMENTS.SOURCE_DETAILS")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('RESELLER_PAYMENTS.TRANSACTION_ID')},on:{"change":function () {
              _vm.onFormChanged();
            }},model:{value:(_vm.resellerPayment.transaction_id),callback:function ($$v) {_vm.$set(_vm.resellerPayment, "transaction_id", $$v)},expression:"resellerPayment.transaction_id"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.transaction_id}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":_vm.$t('RESELLER_PAYMENTS.TRANSACTION_DATA')}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.resellerPayment.transaction_data),expression:"resellerPayment.transaction_data"}],staticClass:"form-control",attrs:{"rows":"6"},domProps:{"value":(_vm.resellerPayment.transaction_data)},on:{"change":function () {
                _vm.onFormChanged();
              },"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.resellerPayment, "transaction_data", $event.target.value)}}})]),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.transaction_data}})],1)])]):_vm._e(),_c('div',{staticClass:"my-4"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.resellerPayment.id ? _vm.$t("RESELLER_PAYMENTS.EDIT_RESELLER_PAYMENT") : _vm.$t("RESELLER_PAYMENTS.ADD_RESELLER_PAYMENT"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }